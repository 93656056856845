import { Expose } from 'class-transformer';
import { Model } from './model';
import { Moment } from 'moment';

class CategoryFormula extends Model {

  @Expose()
  public thisTablesId: number;
  
  @Expose()
  public report_id: number;

  @Expose()
  public category_id: number;

  @Expose()
  public category_name: string;

  @Expose()
  public formula_id: number;

  @Expose()
  public formula_name: string;

  @Expose()
  public application: string | null;

  @Expose()
  public dose: number;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment | null,
    thisTablesId: number,
    report_id: number,
    category_id: number,
    category_name: string,
    formula_id: number,
    formula_name: string,
    application: string | null,
    dose: number,
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.thisTablesId = thisTablesId;
    this.report_id = report_id;
    this.category_id = category_id;
    this.category_name = category_name;
    this.formula_id = formula_id;
    this.formula_name = formula_name;
    this.application = application;
    this.dose = dose;
  }
}

export { CategoryFormula };
